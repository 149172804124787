import { PrivacyPolicyContainer, PrivacyPolicyContainerProps } from '@/components/privacy-policy-section-container';
import { configs } from '@/constants/configs';
import { PRIVACY_POLICIES } from '@/constants/privacy-policy';
import { DefaultLayout } from '@/layouts/default-layout/DefaultLayout';
import '../styles/global.scss';
import '../styles/privacy-policy-page.scss';

const pageName: string = 'Privacy Policy';

export default function TermsOfUse() {
	return (
		<DefaultLayout className="privacy-policy-page" pageTitle={`${configs.defaultPageTitle} - ${pageName}`}>
      <div className="privacy-policy-page__header-wrapper" data-content={pageName}>
        <h2 className="privacy-policy-page__header atmospheric-text">
          {pageName}
        </h2>
      </div>
			<ol className="privacy-policy-page__section-list corbel-text">
				{PRIVACY_POLICIES.map(
					(policy: PrivacyPolicyContainerProps, index: number) => (
						<PrivacyPolicyContainer {...policy} key={index} />
					)
				)}
			</ol>
		</DefaultLayout>
	);
}
