import React from 'react';
import './privacy-policy-container.scss';

export interface PrivacyPolicySectionProps {
	header: React.ReactNode;
	policy: React.ReactNode;
}

export interface PrivacyPolicySectionImageProps {
	imageUrl: string;
	style: React.CSSProperties;
}

export interface PrivacyPolicyContainerProps {
	list: PrivacyPolicySectionProps[];
	decorations?: PrivacyPolicySectionImageProps[];
}

const PrivacyPolicyContainer: React.FC<PrivacyPolicyContainerProps> = ({
	list,
	decorations,
}: PrivacyPolicyContainerProps) => {
	return (
		<li className="pp-container">
			{list.map((item: PrivacyPolicySectionProps, index: number) => (
				<section key={index}>
					<h3 className="atmospheric-text">{item.header}</h3>
					<p dangerouslySetInnerHTML={{ __html: item.policy as string }} />
				</section>
			))}
			{decorations?.map(
				(item: PrivacyPolicySectionImageProps, index: number) => (
					<img className="pp-container__deco" src={item.imageUrl} style={item.style} key={index} />
				)
			)}
		</li>
	);
};

export { PrivacyPolicyContainer };
